import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'components/link/Link';
import { motion } from 'framer-motion'

const NotFound: React.FC<RouteComponentProps> = () => (
    <motion.div
        exit={{}}
        style={{ height: '100vh' }}
        initial={{ y: '-100vh' }}
        animate={{ y: '0vh', transition: { delay: .5 } }}
    >
        <Helmet title="NoA Ignite — Not Found" />

        <div className="centered">
            <h1 className="h1">Oh no</h1>
            <p>
                We can’t find that page. <Link text to="/">Back to start</Link>
            </p>
        </div>
    </motion.div>
);

export default NotFound;
