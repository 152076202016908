import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from '@reach/router';
import cn from 'classnames'

import './Link.scss'

interface LinkProps {
    children: React.ReactNode;
    to: string;
    onClick?: any;
    external?: boolean;
    className?: any;
    text?: boolean;
    [key: string]: any;
}

export const Link = ({
    children,
    external = false,
    text,
    to,
    onClick = () => { },
    ...props
}: LinkProps): JSX.Element => {
    const { pathname } = useLocation()
    const [touching, setTouching] = useState(false)

    if (external) {
        return (
            <a
                href={to}
                {...props}
                className={cn('link', props.className, {
                    'link--touching': touching,
                    'link--text': text
                })}
                onTouchStart={() => {
                    setTouching(true)
                }}
                onTouchEnd={() => {
                    setTouching(false)
                }}
                onTouchCancel={() => {
                    setTouching(false)
                }}
            >
                {text ? <span>{children}</span> : children}
            </a>
        )
    }

    return (
        <RouterLink
            to={to}
            {...props}
            getProps={({isCurrent}) => {
                return { 
                    className: cn('link', props.className, { 
                        'link--touching': touching,  
                        'link--text': text,
                        'link--active': isCurrent 
                    }) 
                }
            }}
            onClick={e => {
                if (e.currentTarget.pathname === pathname && !e.currentTarget.href.includes('#')) {
                    e.preventDefault()
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                } else {
                    onClick(e)
                }
            }}
            onTouchStart={() => {
                setTouching(true)
            }}
            onTouchEnd={() => {
                setTouching(false)
            }}
            onTouchCancel={() => {
                setTouching(false)
            }}
        >
            {text ? <span>{children}</span> : children}
        </RouterLink>
    )
};
