import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Page from 'components/page/Page';
import { Container } from 'components/container/Container';
import { Footer } from 'components/footer/Footer';

const Cookies: React.FC<RouteComponentProps & { directionLeft: boolean }> = ({
    directionLeft,
}: {
    directionLeft: boolean;
}) => {
    const [cookieInformation, setCookieInformation] = useState<any>(null);

    useEffect(() => {
        const secondScriptLoaded = () => {
            const cookieTextScript: any = document.getElementById('cookie-information-declaration');

            setCookieInformation(cookieTextScript.nextSibling.innerHTML);

            document.body.removeChild(cookieTextScript.nextSibling);
        };
        const firstScriptLoaded = () => {
            const cookieTextScript: any = document.getElementById('cookie-information-declaration');

            cookieTextScript.onload = secondScriptLoaded;
        };
        const script = document.createElement('script');

        script.onload = firstScriptLoaded;
        script.src = 'https://policy.app.cookieinformation.com/cid.js';
        script.setAttribute('id', 'CookiePolicy');
        script.setAttribute('data-culture', 'EN');

        document.body.appendChild(script);
    }, []);

    return (
        <Page title="NoA Ignite — Privacy Policy" directionLeft={directionLeft}>
            <Container>
                <h1 className="h1" style={{ marginTop: '1em', marginBottom: '.5em' }}>
                    Privacy Policy
                </h1>

                <div className="raw-text" dangerouslySetInnerHTML={{ __html: cookieInformation }} />
            </Container>

            <Footer />
        </Page>
    );
};

export default Cookies;
