import './Image.scss'
import React, { forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react'
import cn from 'classnames'

interface ImageProps {
    alt?: string;
    fullOpacity?: number;
    width: number;
    height: number;
    className?: any;
    onLoad?: () => void;
    src: string;
    srcSet?:string;
    sizes?:string
}

export default forwardRef(({
    alt = '',
    width,
    height,
    fullOpacity = 1,
    className,
    onLoad,
    src,
    ...rest
}: ImageProps, forwardedRef) => {
    const ref = useRef<HTMLImageElement>(null)
    const [loaded, setLoaded] = useState(false)

    useImperativeHandle(forwardedRef, () => ref.current)

    useEffect(() => {
        if (ref.current && ref.current.complete) {
            setLoaded(true)
        }
    }, [])

    return (
        <div
            style={{
                paddingBottom: `${height / width * 100}%`
            }}
            className={cn(className, 'image', {
                'image--loaded': loaded,
            })}
        >
            <img
                ref={ref}
                alt={alt}
                className="image__src"
                onLoad={() => {
                    setLoaded(true)

                    if (onLoad) {
                        onLoad()
                    }
                }}
                src={src}
                style={{
                    '--full-opacity': fullOpacity
                } as any}
                {...rest}
            />
        </div>
    )
})
