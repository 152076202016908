import './styles/app.scss';

import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';

import Contact from 'pages/contact';
import Services from 'pages/services';
import NotFound from 'pages/404';
import Home from 'pages/home';
import Cookies from 'pages/cookies';
import Header from 'components/header/Header';
import { Router, Location, globalHistory } from '@reach/router';
import { Helmet } from 'react-helmet';
import { AnimatePresence } from 'framer-motion';
import FontFaceObserver from 'fontfaceobserver';

import smoothscroll from 'smoothscroll-polyfill';
import { Cursor } from 'components/cursor/Cursor';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import PrivacyPolicy from 'pages/privacy-policy';
import * as serviceWorker from './serviceWorker';
import activateCookie from './cookiemonster';

smoothscroll.polyfill();
window.history.scrollRestoration = 'manual';

const FramerRouter = ({ children }: { children: any }) => (
    <Location>
        {({ location }) => (
            <>
                <Header location={location} />
                <AnimatePresence initial={false}>
                    <Router key={location.key} location={location} primary={false}>
                        {children}
                    </Router>
                </AnimatePresence>
            </>
        )}
    </Location>
);

function FontLoader({ children }: { children: any }): JSX.Element | null {
    const [loading, setLoading] = useState(true);
    const load = useCallback(async () => {
        try {
            await Promise.all([
                new FontFaceObserver('Aften Screen', { weight: 400 }).load(),
                new FontFaceObserver('Aften Screen', { weight: 600 }).load(),
            ]);
        } catch (e) {
            // do nothing
        } finally {
            // kill basic spinner
            const spinner: any = document.getElementById('spinner');

            if (spinner) {
                spinner.remove();
            }

            setLoading(false);
            setTimeout(activateCookie, 6000);
        }
    }, []);

    useEffect(() => {
        load();
    }, [load]);

    return !loading ? <>{children}</> : null;
}

const App = () => {
    const initialRouterState = {
        previous: {
            location: globalHistory.location,
            navigate: globalHistory.navigate,
        },
        current: {
            location: globalHistory.location,
            navigate: globalHistory.navigate,
        },
    };
    // const [routerState, setRouterState] = useState(initialRouterState);
    const [directionLeft, setDirection] = useState(false);

    useEffect(() => {
        const pages = ['/services', '/', '/contact'];
        const onKeyDown = ({ keyCode }: any) => {
            if (keyCode === 9) {
                document.body.classList.add('user-is-tabbing');
                window.removeEventListener('keydown', onKeyDown);
            }
        };
        const onMouseDown = () => {
            document.body.classList.remove('user-is-tabbing');
            window.removeEventListener('mousedown', onKeyDown);
            window.addEventListener('keydown', onKeyDown);
        };

        const removeListener = globalHistory.listen((params) => {
            const { location } = params;
            const newState = { ...initialRouterState.current, location };
            if (pages.indexOf(initialRouterState.current.location.pathname) < pages.indexOf(location.pathname)) {
                setDirection(false);
            } else {
                setDirection(true);
            }
            // setRouterState({
            //     current: newState,
            //     previous: initialRouterState.current
            // });
            initialRouterState.current = newState;
        });

        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('mousedown', onMouseDown);
            removeListener();
        };
    }, [initialRouterState]);

    return (
        <ErrorBoundary>
            <Helmet title="Home" />
            <Cursor />
            <FontLoader>
                <FramerRouter>
                    <Home path="/" directionLeft={directionLeft} />
                    <Services path="/services" directionLeft={directionLeft} />
                    <Contact path="/contact" directionLeft={directionLeft} />
                    <Cookies path="/cookies" directionLeft={directionLeft} />
                    <PrivacyPolicy path="/privacy-policy" directionLeft={directionLeft} />
                    <NotFound default />
                </FramerRouter>
            </FontLoader>
        </ErrorBoundary>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
