import React, { ReactNode } from 'react';
import Block from 'components/block/Block';
import AnimateIn from 'components/animate-in/AnimateIn';
import './TwoColumnSection.scss';
import { Divider1 } from 'components/divider/Divider1';

interface TwoColumnSectionProps {
    title: string;
    children: ReactNode;
}

export const TwoColumnSection = ({ title, children }: TwoColumnSectionProps): JSX.Element => (
    <Block>
        <div className="two-column-section">
            <AnimateIn>
                <h2 className="h1b two-column-section__title">
                    {title}
                    <span
                        style={{
                            fontSize: '18px',
                            verticalAlign: 'top',
                            color: '#fa0610',
                            marginLeft: '0.25em',
                            marginTop: '0.5em',
                            letterSpacing: '0em',
                            display: 'inline-block',
                        }}
                    >
                        (04)
                    </span>
                </h2>
            </AnimateIn>
            <AnimateIn>
                <div className="two-column-section__intro">
                    <p>Design can change the way consumers and brands interact with one another. </p>
                    <p>
                        Greatly designed digital solutions can raise the bar for what a customer experience can be and
                        define new positive ways to benefit humans, individuals and businesses.{' '}
                    </p>
                </div>
            </AnimateIn>
            <div className="two-column-section__items">{children}</div>
        </div>
    </Block>
);

interface TwoColumnSectionItemProps {
    children: ReactNode;
    offset: number;
}

export const TwoColumnSectionItem = ({ children, offset }: TwoColumnSectionItemProps): JSX.Element => (
    <div className="two-column-section__item">
        <Divider1> </Divider1>
        <AnimateIn offset={offset}>
            <div className="two-column-section__item-container">{children}</div>
        </AnimateIn>
    </div>
);
