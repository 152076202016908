import React from 'react';
import { Container } from 'components/container/Container';
import Block from 'components/block/Block';
import cn from 'classnames';
import './TextBlock.scss';
import { Column1 } from 'components/column/Column1';

export function TextBlock1({
    children,
    right = false,
    divider = false,
}: {
    children: any;
    right?: boolean;
    divider?: boolean;
}): JSX.Element {
    return (
        <Block tight>
            {divider ? (
                <Column1 size="11" center>
                    <hr
                        style={{
                            borderTop: '1px solid #979797',
                            width: 'inheritant',
                            margin: 'auto',
                            paddingBottom: '22px',
                        }}
                    />
                </Column1>
            ) : (
                ' '
            )}

            <Container>
                <div
                    className={cn('text-block', {
                        'text-block--right': right,
                    })}
                >
                    <div className=" text-block__text">{children}</div>
                </div>
            </Container>
        </Block>
    );
}

export function TextBlockTitle({ children, count }: { children: any; count?: number }): JSX.Element {
    return (
        <h2 className="h1b text-block__title">
            {children}
            {count ? (
                <span
                    style={{
                        fontSize: '18px',
                        verticalAlign: 'top',
                        color: '#fa0610',
                        marginLeft: '0.25em',
                        marginTop: '0.5em',
                        letterSpacing: '0em',
                        display: 'inline-block',
                    }}
                >
                    {`(${count})`}
                </span>
            ) : (
                ''
            )}
        </h2>
    );
}

export function TextBlockTotalCount({ children }: { children: any }): JSX.Element {
    return (
        <div className="count-position">
            <hr
                style={{
                    borderTop: '1px solid #979797',
                }}
            />
            <div className="row1">
                <p style={{ marginTop: '10px' }}>— Total number of recognitions</p>
                <h2 className="h1 count">{children}</h2>
            </div>
        </div>
    );
}
