import React, { useEffect, useRef, useState } from 'react';
import './Cursor.scss';

export const Cursor = (): JSX.Element => {
    const cursor = useRef<any>()
    const [hasMouse, setHasMouse] = useState(true)

    useEffect(() => {
        const {body} = document
        const activators = ['BUTTON', 'A']
        const resetCursor = ()=> { 
            cursor.current.style.width = ''
            cursor.current.style.height = ''
            cursor.current.style.background = ''
        }
        const onMouseMove = (e: any) => {
            if (!hasMouse) {
                return
            }

            cursor.current.style.display = 'block'
            cursor.current.style.transform = `translate(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%))`
        }
        const onMouseEnter = () => {
            if (!hasMouse) {
                return
            }

            cursor.current.style.display = 'block'
        }
        const onMouseLeave = () => {
            if (!hasMouse) {
                return
            }

            cursor.current.style.display = 'none'
        }
        const onMouseEnterCapture = (e: any) => {
            if (activators.includes(e.target.tagName) || e.target.classList.contains('.actionable')) {
                cursor.current.style.width = '2em'
                cursor.current.style.height = '2em'
                cursor.current.style.background = '#FFF'
            }
        }
        const onMouseLeaveCapture = (e: any) => {
            if (activators.includes(e.target.tagName) || e.target.classList.contains('.actionable')) {
                resetCursor()
            }
        }
        const onClick = () => {
            resetCursor()
        }
        const onTouchStart = () => {
            setHasMouse(false)
            body.removeEventListener('touchstart', onTouchStart)
        }

        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('click', onClick)
        body.addEventListener('mouseleave', onMouseLeave)
        body.addEventListener('mouseenter', onMouseEnter)
        body.addEventListener('mouseenter', onMouseEnterCapture, { capture: true })
        body.addEventListener('mouseleave', onMouseLeaveCapture, { capture: true })
        body.addEventListener('touchstart', onTouchStart)

        return () => {
            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('click', onClick)
            body.removeEventListener('mouseenter', onMouseEnter)
            body.removeEventListener('mouseleave', onMouseLeave)
            body.removeEventListener('mouseenter', onMouseEnterCapture)
            body.removeEventListener('mouseleave', onMouseLeaveCapture)
            body.addEventListener('touchstart', onTouchStart)
        }
    }, [hasMouse])

    return (
        <div
            ref={cursor}
            className="cursor"
            style={{
                display: !hasMouse ? 'none' : 'block'
            }}
        />
    )
};
