import { RouteComponentProps } from '@reach/router';
import { Footer } from 'components/footer/Footer';
import Page from 'components/page/Page';
import React from 'react';
import PrivacyPolicyModule from '../components/privacy-policy/PrivacyPolicy';

const PrivacyPolicy: React.FC<RouteComponentProps & { directionLeft: boolean }> = ({
    directionLeft,
}: {
    directionLeft: boolean;
}) => {
    return (
        <Page title="NoA Ignite — Privacy Policy" directionLeft={directionLeft}>
            <PrivacyPolicyModule />
            <div style={{ marginTop: '5em' }} />
            <Footer direction="right" leftColumn="home" rightColumn="contact" />
        </Page>
    );
};
export default PrivacyPolicy;
