import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

export default function AnimateIn({
    className,
    children,
    offset = 0,
}: {
    className?: any;
    children: any;
    offset?: number;
}): JSX.Element {
    const ref = useRef<any>();
    const [visible, setVisible] = useState(false);
    let posY = 60;
    if (window.matchMedia('(max-width: 850px)').matches) {
        posY = 20;
    }

    const variants = {
        hidden: {
            opacity: 0,
            y: posY,
        },
        show: {
            opacity: 1,
            y: 0,
        },
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.25 },
        );
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <motion.div
            initial="hidden"
            animate={visible ? 'show' : 'hidden'}
            transition={{ type: 'spring', damping: 15, mass: 0.3, stiffness: 55 }}
            exit="hidden"
            variants={variants}
            className={className}
            ref={ref}
        >
            {children}
        </motion.div>
    );
}
