import React, { useRef } from 'react';
import cn from 'classnames';
import './Block.scss';

export default function Block({
    children, 
    tight = false
}: { children: any, tight?:boolean }): JSX.Element {
    const ref = useRef<any>()

    return (
        <div
            ref={ref}
            className={cn('block', { 
                'block--tight': tight
            })}
        >
            {children}
        </div>
    )
}
