import React from 'react';

import './ImageSection.scss';
import { Container } from 'components/container/Container';
import Block from 'components/block/Block';
import Image from 'components/image/Image';

interface ImageSectionProps {
    width: number;
    height: number;
    src: string; 
}

export const ImageSection = ({ width, height,  src }: ImageSectionProps): JSX.Element => (
    <Block> 
        <div className="image-section">
            <Container>
                <div className="image-section__img" >
                    <Image width={width} height={height}  src={src} />
                </div> 
            </Container>
        </div>
    </Block>
);
