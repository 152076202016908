import React, { useRef, useEffect, useState } from 'react';
import { Container } from 'components/container/Container';
import AnimateIn from 'components/animate-in/AnimateIn';
import { motion } from 'framer-motion';
import { TextSplit } from 'components/text-split/TextSplit';
import cn from 'classnames';

import './Hero.scss';
import { Column } from 'components/column/Column';

export function HeroTextOverlay({
    sticky,
    watcherId,
    toggleSticky,
    introDone,
    directionLeft,
}: {
    toggleSticky: any;
    sticky: boolean;
    watcherId: string;
    introDone: boolean;
    directionLeft?: boolean;
}): JSX.Element {
    useEffect(() => {
        setInterval(() => {
            const { innerHeight } = window;
            const watcher = document.getElementById(watcherId);
            const height = 200;
            if (watcher) {
                const { bottom } = watcher.getBoundingClientRect();
                // console.log(bottom, innerHeight, bottom - innerHeight);
                if (bottom - innerHeight + height > 0 && !sticky) {
                    toggleSticky(true);
                }
            }
        }, 1000);
        const onScroll = () => {
            const { innerHeight } = window;
            const watcher = document.getElementById(watcherId);
            const height = 200;
            if (watcher) {
                const { bottom } = watcher.getBoundingClientRect();
                if (bottom - innerHeight + height <= 0 && sticky) {
                    toggleSticky(false);
                }
                if (bottom - innerHeight + height > 0 && !sticky) {
                    toggleSticky(true);
                }
            }
        };
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [sticky, watcherId, toggleSticky]);

    return (
        <motion.div
            initial={{ x: directionLeft ? '-100%' : '100%' }}
            transition={{ x: { duration: 1, ease: [0.83, 0, 0.17, 1] } }}
            animate={{ x: '0%' }}
            exit={{ x: directionLeft ? '100%' : '-100%' }}
            aria-hidden
            className={cn('hero-text', {
                'hero-text--fake': !sticky,
            })}
        >
            <TextSplit isActive={introDone} type="h1b">
                <Container>
                    <Column size="8" center>
                        Designed to stand out,
                        <br /> fit humans and build business.
                    </Column>
                </Container>
            </TextSplit>
        </motion.div>
    );
}
export function Hero({
    sticky,
    watcherId,
    introDone,
}: {
    sticky: boolean;
    watcherId: string;
    introDone: boolean;
}): JSX.Element {
    const video = useRef<HTMLVideoElement | any>();
    const [[videoUrl, posterUrl]] = useState(() => {  
        return ['/static/videos/ripple.mp4', '/static/images/ripple.jpg'];
    }); 

    return (
        <>
            <div className="hero">
                <video
                    preload="auto"
                    muted
                    loop
                    autoPlay
                    ref={video}
                    playsInline
                    src={videoUrl}
                    className="hero__video"
                    poster={posterUrl}
                />
            </div>

            <div
                className={cn('hero-text hero-text--ghost h1b', {
                    'hero-text--real': !sticky,
                })}
                // id={watcherId}
            >
                <Container>
                    <div>
                        <Column size="8" center>
                            <h2>
                                <div id={watcherId}>
                                    Designed to stand out,
                                    <br /> fit humans and build business<span className="dot">.</span>
                                </div>
                                <div className={cn({ 'grey-color fade-in': sticky === false })}>
                                    That&apos;s the winning digital experience we create.{' '}
                                </div>
                            </h2>
                        </Column>
                    </div>
                </Container>
            </div>
            <Container className="hero-subtext">
                <AnimateIn>
                    <Column size="8" center>
                        <div className="row">
                            <p className="distance1">
                                Customers are expecting delightful and empowering digital products and services that
                                make their lives better. Digital solutions need to stand out and be cleverly
                                personalised to solve human problems. And solving human problems ultimately builds
                                business.{' '}
                            </p>
                            <p className="distance2">
                                We combine deep customer insights, strategy, creative excellence, data and technology to
                                invent, design, develop and launch market-leading solutions that help companies win. We
                                deliver measurable impact today with longevity sewn in.
                            </p>
                        </div>
                    </Column>
                </AnimateIn>
            </Container>
        </>
    );
}
