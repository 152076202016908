import { Column1 } from 'components/column/Column1';
import React, { ReactNode } from 'react';

// import './Column.scss';

export function Divider({ children }: { children: ReactNode }): JSX.Element {
    return (
        <Column1 size="11" center>
            <div
                style={{
                    borderTop: '1px solid #979797',
                    width: 'inheritant',
                    margin: 'auto',
                    paddingBottom: '22px',
                    maxWidth: '1700px',
                }}
            >
                <hr />
                {children}
            </div>
        </Column1>
    );
}
