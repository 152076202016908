/* eslint-disable react/state-in-constructor */
import React from 'react';
import { Helmet } from 'react-helmet';

export default class ErrorBoundary extends React.Component<{ children: any }> {
    state = {
        hasError: false
    }

    static getDerivedStateFromError(): any {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(): any { 
        document.body.classList.add('has-error') 
    }

    render(): any {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) { 
            return (
                <>
                    <Helmet title="Oh no :(" />

                    <div className="centered">
                        <h1 className="h1">Oh no :(</h1>
                        <p>
                            Something crashed. <a href="/">Back to start</a>
                        </p>
                    </div>
                </>
            )
        }

        return children;
    } 
}