import React from 'react';
import { Container } from 'components/container/Container';
import Block from 'components/block/Block';
import AnimateIn from 'components/animate-in/AnimateIn';
import cn from 'classnames';
import './FourColumnSection.scss';

export function FourColumnSection({ children }: { children: any }): JSX.Element {
    return (
        <Block>
            <Container>
                <AnimateIn>
                    {React.cloneElement(children[0], {
                        className: `four-column-section__title ${children[0].props.className}`,
                    })}
                </AnimateIn>
                <div className="four-column-section__content">{children.slice(1)}</div>
            </Container>
        </Block>
    );
}

export function FourColumnSectionBlock({
    children,
    offset,
    className,
}: {
    children: any;
    offset: number;
    className?: string;
}): JSX.Element {
    return (
        <div className={cn('four-column-section__content__item', className)}>
            <AnimateIn offset={offset}>{children}</AnimateIn>
        </div>
    );
}
