import React, { ReactNode } from 'react';

export function Divider1({ children }: { children: ReactNode }): JSX.Element {
    return (
        <div
            style={{
                borderTop: '1px solid #979797',
                width: '100%',
                margin: 'auto',
                paddingBottom: '22px',
            }}
        >
            <hr />
            {children}
        </div>
    );
}
