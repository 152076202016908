import React, { useEffect, useState } from 'react';
import './LogoModule.scss';
import cn from 'classnames';
import { Column1 } from 'components/column/Column1';

export const LogoModule = (): JSX.Element => {
    const [visibleIndex, setVisibleIndex] = useState(0);
    const [visibleIndex2, setVisibleIndex2] = useState(0);
    const [visibleIndex3, setVisibleIndex3] = useState(0);
    // let test = ((Math.random()*4)+1)*(1000)
    useEffect(() => {
        const id = setInterval(() => {
            let newIndex = visibleIndex + 1;
            if (newIndex > 6) {
                newIndex = 0;
            }
            setVisibleIndex(newIndex);

            setTimeout(() => {
                let newIndex1 = visibleIndex2 + 1;
                if (newIndex1 > 6) {
                    newIndex1 = 0;
                }
                setVisibleIndex2(newIndex1);
            }, 500);

            setTimeout(() => {
                let newIndex2 = visibleIndex3 + 1;
                if (newIndex2 > 6) {
                    newIndex2 = 0;
                }
                setVisibleIndex3(newIndex2);
            }, 1000);
        }, 2500);

        return () => clearInterval(id);
    }, [visibleIndex, visibleIndex2, visibleIndex3]);

    return (
        <Column1 size="8" center>
            <div className="flex">
                <div className="logo a">
                    <img
                        src="/static/images/hello-great-works-tv-2.svg"
                        alt="TV2"
                        className={cn({ 'is-visible': visibleIndex === 0 })}
                    />
                    <img
                        src="/static/images/hello-great-works-maersk.svg"
                        alt="Maersk"
                        className={cn({ 'is-visible': visibleIndex === 1 })}
                    />
                    <img
                        src="/static/images/hello-great-works-google.svg"
                        alt="Google"
                        className={cn({ 'is-visible': visibleIndex === 2 })}
                    />
                    <img
                        src="/static/images/hello-great-works-orsted.svg"
                        alt="Ørsted"
                        className={cn({ 'is-visible': visibleIndex === 3 })}
                    />
                    <img
                        src="/static/images/hello-great-works-coop.svg"
                        alt="Coop"
                        className={cn({ 'is-visible': visibleIndex === 4 })}
                    />
                    <img
                        src="/static/images/hello-great-works-novo-nordisk.svg"
                        alt="Novo Nordisk"
                        className={cn({ 'is-visible': visibleIndex === 5 })}
                    />
                    <img
                        src="/static/images/hello-great-works-danske-spil.svg"
                        alt="Danske Spil"
                        className={cn({ 'is-visible': visibleIndex === 6 })}
                    />
                </div>
                <div className="logo b">
                    <img
                        src="/static/images/hello-great-works-tdc.svg"
                        alt="TDC"
                        className={cn({ 'is-visible': visibleIndex2 === 0 })}
                    />
                    <img
                        src="/static/images/hello-great-works-roskilde-festival.svg"
                        alt="Roskilde Festival"
                        className={cn({ 'is-visible': visibleIndex2 === 1 })}
                    />
                    <img
                        src="/static/images/hello-great-works-nordea.svg"
                        alt="Nordea"
                        className={cn({ 'is-visible': visibleIndex2 === 2 })}
                    />
                    <img
                        src="/static/images/hello-great-works-mobile-pay.svg"
                        alt="MobilePay"
                        className={cn({ 'is-visible': visibleIndex2 === 3 })}
                    />
                    <img
                        src="/static/images/hello-great-works-zero-north.svg"
                        height="120px"
                        width="auto"
                        alt="ZeroNorth"
                        className={cn({ 'is-visible': visibleIndex2 === 4 })}
                    />
                    <img
                        src="/static/images/hello-great-works-dsb.svg"
                        alt="DSB"
                        className={cn({ 'is-visible': visibleIndex2 === 5 })}
                    />
                    <img
                        src="/static/images/hello-great-works-velux.svg"
                        alt="Velux"
                        className={cn({ 'is-visible': visibleIndex2 === 6 })}
                    />
                </div>
                <div className="logo c">
                    <img
                        src="/static/images/hello-great-works-interflora.svg"
                        alt="Interflora"
                        className={cn({ 'is-visible': visibleIndex3 === 0 })}
                    />
                    <img
                        src="/static/images/hello-great-works-danske-bank.svg"
                        alt="DanskeBank"
                        className={cn({ 'is-visible': visibleIndex3 === 1 })}
                    />
                    <img
                        src="/static/images/hello-great-works-nuuday.svg"
                        alt="Nuuday"
                        className={cn({ 'is-visible': visibleIndex3 === 2 })}
                    />
                    <img
                        src="/static/images/hello-great-works-codan.svg"
                        alt="Codan"
                        className={cn({ 'is-visible': visibleIndex3 === 3 })}
                    />
                    <img
                        src="/static/images/Coloplast.svg"
                        alt="Coloplast"
                        className={cn({ 'is-visible': visibleIndex3 === 4 })}
                    />
                    <img
                        src="/static/images/hello-great-works-han-kjobenhavn.svg"
                        alt="Han København"
                        className={cn({ 'is-visible': visibleIndex3 === 5 })}
                    />
                    <img
                        src="/static/images/hello-great-works-you-see.svg"
                        alt="YouSee"
                        className={cn({ 'is-visible': visibleIndex3 === 6 })}
                    />
                </div>
            </div>
        </Column1>
    );
};
