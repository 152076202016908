import './contact.scss';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Container } from 'components/container/Container';
import { Footer } from 'components/footer/Footer';
import { FourColumnSection, FourColumnSectionBlock } from 'components/four-column-section/FourColumnSection';
import { Link } from 'components/link/Link';
import Page from 'components/page/Page';
import { ImageSection } from 'components/image-section/ImageSection';
import Quote from 'components/quote/Quote';
import { TextBlock } from 'components/text-block/TextBlock';
import { Divider1 } from 'components/divider/Divider1';

const Contact: React.FC<RouteComponentProps & { directionLeft: boolean }> = ({
    directionLeft,
}: {
    directionLeft: boolean;
}) => {
    return (
        <Page title="NoA Ignite — Contact" directionLeft={directionLeft}>
            <div className="page__container">
                <FourColumnSection>
                    <h1 className="h1">Let&apos;s create something great.</h1>
                    <TextBlock right>
                        NoA Ignite Denmark is the Danish branch of NoA Ignite. We&apos;re located in the heart of
                        Copenhagen and our sisters and brothers are located in Norway, Sweden and Poland. We combine
                        deep customer insights, strategy, creative excellence, data and technology to invent, design,
                        develop and launch market-leading solutions that help companies win. We deliver measurables
                        impact today with longevity sawn in.
                    </TextBlock>
                    <Divider1> </Divider1>
                    <FourColumnSectionBlock offset={0}>
                        <address className="contact__address">
                            NoA Ignite <br />
                            Adelgade 12 <br />
                            DK-1304 Copenhagen
                        </address>
                        <ul>
                            <li>
                                <Link text external to="mailto:hey@noaignite.com">
                                    hey@noaignite.com
                                </Link>
                            </li>
                            <li>
                                <Link text external to="tel:+45 8888 6600">
                                    +45 8888 6600
                                </Link>
                            </li>
                            <li>
                                <Link text to="/cookies">
                                    Privacy policy
                                </Link>
                            </li>
                        </ul>
                    </FourColumnSectionBlock>
                    <FourColumnSectionBlock offset={1} className="no-margin">
                        <ul>
                            <li>
                                <Link text external to="https://www.facebook.com/noaignitedk">
                                    Facebook
                                </Link>
                            </li>
                            <li>
                                <Link text external to="https://www.linkedin.com/company/noaignitedk/">
                                    LinkedIn
                                </Link>
                            </li>
                        </ul>
                    </FourColumnSectionBlock>
                </FourColumnSection>

                <Container>
                    <div className="contact__other-offices">
                        <h2 className=" contact__other-offices__title h2b">
                            Our other offices
                            <span
                                style={{
                                    fontSize: '18px',
                                    verticalAlign: 'top',
                                    color: '#fa0610',
                                    marginLeft: '0.25em',
                                    marginTop: '-0.25em',
                                    letterSpacing: '0em',
                                    display: 'inline-block',
                                }}
                            >
                                (03)
                            </span>
                        </h2>
                        <p className=" contact__other-offices__p">
                            {' '}
                            300+ people, four countries, one goal to rule them all: Being your powerhouse for digital
                            growth.
                        </p>
                        <ul className="contact__other-offices__list">
                            <li>
                                <Link text external to="https://www.noaignite.no">
                                    NoA Norway
                                </Link>
                            </li>
                            <li>
                                <Link text external to="https://www.noaignite.se">
                                    NoA Sweden
                                </Link>
                            </li>
                            <li>
                                <Link text external to="https://www.noaignite.pl">
                                    NoA Poland
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className=" contact__careers">
                        <h2 className=" contact__careers__title h2b">
                            Join us at NoA Ignite
                            <span
                                style={{
                                    fontSize: '18px',
                                    verticalAlign: 'top',
                                    color: '#fa0610',
                                    marginLeft: '0.25em',
                                    marginTop: '-0.25em',
                                    letterSpacing: '0em',
                                    display: 'inline-block',
                                }}
                            >
                                (01)
                            </span>
                        </h2>
                        <p className=" contact__careers__p"> Come work with us and stay up to date on new openings.</p>

                        <Link text external to="https://careers-dk.noaignite.com/">
                            See all jobs
                        </Link>
                    </div>
                </Container>

                <ImageSection width={1377} height={815} src="/static/images/hgw-courtyard.jpg" />

                <Container>
                    <FourColumnSectionBlock offset={0} className="no-padding">
                        <p>
                            Part of the{' '}
                            <Link text external to="https://www.thenorthalliance.com/">
                                <span>NoA</span> Family
                            </Link>
                            .
                        </p>
                    </FourColumnSectionBlock>
                </Container>
                <Quote>
                    <p>
                        — NoA is an international community of creatives, built on the Nordic values of equality,
                        diversity, and collaboration.
                    </p>
                </Quote>

                <Footer direction="left" leftColumn="services" rightColumn="home" />
            </div>
        </Page>
    );
};

export default Contact;
