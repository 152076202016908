import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Container } from 'components/container/Container';
import { Footer } from 'components/footer/Footer';
import Page from 'components/page/Page';
import { TextBlock, TextBlockTitle } from 'components/text-block/TextBlock';
import { TextSplit } from 'components/text-split/TextSplit';
import { ImageSection } from 'components/image-section/ImageSection';
import Block from 'components/block/Block';
import StudioLife from 'components/studio-life/StudioLife';
import StudioLife2 from 'components/studio-life/StudioLife2';
import StudioLife3 from 'components/studio-life/StudioLife3';
import { TwoColumnSection, TwoColumnSectionItem } from 'components/two-column-section/TwoColumnSection';
import { Column } from 'components/column/Column';
import StudioLife4 from 'components/studio-life/StudioLife4';
import './services.scss';

const Services: React.FC<RouteComponentProps & { directionLeft: boolean }> = ({
    directionLeft,
}: {
    directionLeft: boolean;
}) => {
    return (
        <Page title="NoA Ignite — Services" directionLeft={directionLeft}>
            <div className="page__container">
                <Block>
                    <Container>
                        <h1 className="visually-hidden">About NoA Ignite</h1>
                        <TextSplit type="h1">
                            We pair great minds across strategy, design and tech with a can-do attitude to build
                            meaningful solutions.
                        </TextSplit>
                    </Container>
                </Block>

                <ImageSection width={1377} height={815} src="/static/images/rectangle-2.jpg" />

                <TwoColumnSection title="Our capabilities">
                    <TwoColumnSectionItem offset={0}>
                        <h3 className="h2 two-column-section__item-title">01. Strategy</h3>
                        <div>
                            <p>
                                We believe in intentions before artefacts. A measurable business impact is built on
                                research, insights and strategy. We help clients build healthy businesses firmly
                                anchored in strategy that can succeed today, tomorrow, to ever-changing customer needs.
                            </p>
                            <ul className="two-column-section__item-list">
                                <li>Research and Insights </li>
                                <li>Customer Experience </li>
                                <li>Strategy </li>
                                <li>Business Innovation</li>
                            </ul>
                        </div>
                    </TwoColumnSectionItem>
                    <TwoColumnSectionItem offset={1}>
                        <h3 className="h2 two-column-section__item-title">02. Products &amp; services</h3>
                        <div>
                            <p>
                                At NoA Ignite, we produce products and services that empower and delight. We
                                craft digital tools and utilities that address real human needs.
                            </p>
                            <p>
                                Our solutions are built to fit in people’s lives &mdash; and often also their pockets.{' '}
                            </p>
                            <ul className="two-column-section__item-list">
                                <li>Digital products </li>
                                <li>Mobile apps </li>
                                <li>Service Design </li>
                            </ul>
                        </div>
                    </TwoColumnSectionItem>
                    <TwoColumnSectionItem offset={2}>
                        <h3 className="h2 two-column-section__item-title">03. Platforms</h3>
                        <div>
                            <p>
                                More and more often, digital platforms are the faces of brands and companies. We help
                                businesses build meaningful corporate sites, campaign sites, intranet solutions etc.{' '}
                            </p>
                            <ul className="two-column-section__item-list">
                                <li>E-commerce </li>
                                <li>Corporate websites </li>
                                <li>Campaign websites </li>
                                <li>Self service portals</li>
                            </ul>
                        </div>
                    </TwoColumnSectionItem>
                    <TwoColumnSectionItem offset={3}>
                        <h3 className="h2 two-column-section__item-title">04. Technology</h3>
                        <div>
                            <p>
                                We help our clients leverage technology to fuel business growth and enable
                                next-generation services and experiences. We build personal solutions powered by data,
                                and bridge the gap between your distinctive customer experiences and your technical
                                ecosystem.
                            </p>
                            <ul className="two-column-section__item-list">
                                <li>Platform evalution and selection</li>
                                <li>Product development</li>
                                <li>Software architecture </li>
                                <li>Digital capabilities assessment</li>
                                <li>Maintenance </li>
                            </ul>
                        </div>
                    </TwoColumnSectionItem>
                </TwoColumnSection>
                <Container>
                    <StudioLife />
                </Container>
                <Container flex>
                    <TextBlock>
                        <div style={{ marginTop: '4em' }}>
                            <TextBlockTitle>Measurable impact works</TextBlockTitle>
                        </div>
                    </TextBlock>
                    <TextBlock right>
                        <p>
                            At NoA Ignite, we’re driven by the urge to create work that delivers a measurable
                            impact, both short and long term. We measure impact by the number of smiles and pleasant
                            surprises we give people, how well it meets human needs and preferences, and how well it
                            helps businesses succeed.{' '}
                        </p>
                        <p>
                            A winning digital experience can fulfil all of that, and it should. We are here to make it
                            happen.
                        </p>
                    </TextBlock>
                </Container>
                <div style={{ marginTop: '5em', marginLeft: '35px', marginRight: '35px' }}>
                    <Column size="8" center>
                        <TextBlockTitle>Change is led by doers</TextBlockTitle>
                        <br />
                        <p>
                            {' '}
                            Winning takes effort. It requires knowledge, skills, curiosity, craftsmanship and courage.
                            At NoA Ignite, we combine diverse expertises and perspectives to be knowledgeable,
                            well prepared, fast moving and agile. It’s equally important to craft designs that are well
                            thought through and done at the speed of digital reality (fast, that is).
                        </p>
                        <p>
                            We pair great minds across strategy, design and tech with our go-do-attitude. This enables
                            us to solve wicked problems, immediate problems or even dis-solve problem. In other words;
                            We eat problems for breakfast, daily.
                        </p>
                    </Column>
                </div>
                <div className="spacing" />

                <StudioLife2 />
                <StudioLife4 />
                <div style={{ marginTop: '2em' }} />

                <Container flex>
                    <Column size="4">
                        <TextBlockTitle>Work DNA</TextBlockTitle>
                    </Column>
                    <Column size="4">
                        <div style={{ marginRight: '20px' }}>
                            <p>
                                Working in an autonomous team doesn’t only make us happy; studies show it fuels
                                innovation. Allowing employees’ ownership over their own work gives us more time to be
                                creative, which ultimately spins off more new ideas.{' '}
                            </p>
                            <p>
                                We’re dedicated to deliver top-shelf work. Craftsmanship and caring for the details is
                                in our DNA, focus is on building solutions that can last and stay relevant for years.{' '}
                            </p>
                        </div>
                    </Column>
                    <Column size="4">
                        <p>
                            While working autonomously in small teams, we have a collaborative culture. It’s a balance,
                            but it&apos;s doable, and we know for a fact that good ideas grow into great ideas with
                            input from others. Everyone here is equipped with the ability to take responsibility and to
                            listen. We champion infusing specialised competencies unlike our own, we make sure to listen
                            to opposing views and we take in core decision makers from the client side as teammates to
                            give us traction.{' '}
                        </p>
                        <p>
                            We do it all with passion and compassion, in an environment shaped by high ambitions and an
                            urge to also have fun.{' '}
                        </p>
                    </Column>
                </Container>
                <div style={{ marginTop: '5em' }} />
                <Column size="10" center>
                    <StudioLife3 />
                </Column>
                <div style={{ marginTop: '5em' }} />

                <Footer direction="right" leftColumn="home" rightColumn="contact" />
            </div>
        </Page>
    );
};

export default Services;
