import React, { useRef, useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { useTransform, useSpring, motion } from 'framer-motion';
import './Page.scss';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import ResizeObserver from 'resize-observer-polyfill';

export const Page = ({
    children,
    title,
    directionLeft = true,
}: {
    children: any;
    title: string;
    directionLeft?: boolean;
}): JSX.Element => {
    const ref = useRef<any>();
    const [pageHeight, setPageHeight] = useState(0);
    const [isAnimating, setAnimate] = useState(false);
    const [isMobile, setMobile] = useState(false);
    const physics = { damping: 15, mass: 0.3, stiffness: 55 };
    const scrollY = useSpring(0, physics);
    const transform = useTransform(scrollY, (value) => value * -1);
    const location = useLocation();
    const currentLocation = '';

    const resizePageHeight = useCallback((entries) => {
        setPageHeight(entries[0].contentRect.height);
    }, []);

    if (currentLocation !== location.pathname && !isAnimating && ref.current && !isMobile) {
        setAnimate(true);
        scrollY.set(0);
    }

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            resizePageHeight(entries);
        });
        resizeObserver.observe(ref.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [resizePageHeight]);

    useEffect(() => {
        const onScroll = () => {
            scrollY.set(window.pageYOffset);
        };

        const id = setTimeout(() => {
            window.scrollTo(0, 0);
            setAnimate(false);
        }, 1000);

        if (!isMobile) {
            window.addEventListener('scroll', onScroll);
        }

        return () => {
            window.removeEventListener('scroll', onScroll);
            clearTimeout(id);
        };
    }, [scrollY, isMobile]);

    useEffect(() => {
        const onWindowResize = () => {
            if (window.matchMedia('(max-width: 720px)').matches) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        };

        onWindowResize();
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [isMobile]);

    return (
        <>
            <motion.div
                ref={ref}
                initial={{ x: directionLeft ? '-100%' : '100%' }}
                animate={{ x: '0%', overflow: 'hidden' }}
                exit={{ x: directionLeft ? '100%' : '-100%' }}
                transition={{ x: { duration: 1, ease: [0.83, 0, 0.17, 1] } }}
                style={{ y: !isAnimating || isMobile ? 0 : transform }}
                className="page"
            >
                <Helmet title={title} />
                {children}
            </motion.div>
            <div style={{ height: isMobile ? 0 : pageHeight }} />
        </>
    );
};

export default Page;
