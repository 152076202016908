import React from 'react';
import Image from 'components/image/Image';

import './StudioLife.scss';
import { Column } from 'components/column/Column';
import { Container } from 'components/container/Container';

export default function StudioLife4(): JSX.Element {
    return (
        <div className="studio-life">
            <Container flex>
                <Column size="4">
                    <div />
                </Column>
                <Column size="4">
                    <div className="studio-life__images__image">
                        <div className="f">
                            <Image src="/static/images/p1.jpg" width={200} height={248} />
                        </div>
                    </div>
                </Column>
            </Container>
        </div>
    );
}
