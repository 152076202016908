import React from 'react';
import { Container } from 'components/container/Container';
import { TextSplit } from 'components/text-split/TextSplit';
import './Quote.scss';

export default function Quote({ children }: { children: any }): JSX.Element {
    return (
        <Container>
            <blockquote className="quote">
                <TextSplit type="h1">{children}</TextSplit>
            </blockquote>
        </Container>
    );
}
