import React, { ReactNode } from 'react';
import cn from 'classnames';
import  './Container.scss';

interface ContainerProps {
    children: ReactNode;
    flex?: boolean;
    className?: string;
}

export const Container = ({ className, children, flex = false }: ContainerProps): JSX.Element => {
    return  (
        <div className={cn('container',className, {   'container--flex' : flex })}>
            {children}
        </div>
    ) 
};
