import React, { ReactNode, useEffect, useRef } from 'react';
import './Cases.scss';
import { Container } from 'components/container/Container';
import cn from 'classnames';
import Block from 'components/block/Block';
import { TextSplit } from 'components/text-split/TextSplit';
import { Divider } from 'components/divider/Divider';

interface CasesProps {
    children: ReactNode;
    title: string;
}

export const Cases = ({ children, title }: CasesProps): JSX.Element => {
    return (
        <Block>
            <Divider> </Divider>
            <div className="cases">
                <Container>
                    <TextSplit type="cases__title h1b">
                        <h2>{title}</h2>
                    </TextSplit>
                </Container>
                <div className="cases__case">{children}</div>
            </div>
        </Block>
    );
};

interface CaseProps {
    caption: string;
    title: string;
    subtitle: string;
    text: string;
    tags: string[];
    imageUrl?: string;
    videoUrl?: string;
    posterUrl?: string;
    slug: string;
    green?: boolean;
    lightGreen?: boolean;
    gold?: boolean;
}

export const Case = ({
    gold,
    green,
    lightGreen,
    title,
    slug,
    subtitle,
    caption,
    text,
    tags = [],
    imageUrl,
    posterUrl,
    videoUrl,
}: CaseProps): JSX.Element => {
    const wrapper = useRef<any>();
    const video = useRef<any>();

    useEffect(() => {
        if (video.current) {
            const observer = new IntersectionObserver(
                async ([entry]) => {
                    if (entry.isIntersecting) {
                        try {
                            await video.current.play();
                        } catch (e) {
                            // do nothing
                        }
                    } else {
                        video.current.pause();
                    }
                },
                { threshold: [0.1] },
            );

            observer.observe(wrapper.current);

            return () => {
                observer.disconnect();
            };
        }
    }, []);

    return (
        <div
            className={cn('case', {
                'case--green': green,
                'case--light-green': lightGreen,
                'case--gold': gold,
            })}
            ref={wrapper}
            id={slug}
        >
            <div className="case__media">
                {videoUrl ? (
                    <video preload="metadata" playsInline muted loop ref={video} src={videoUrl} poster={posterUrl} />
                ) : null}
                {imageUrl ? <img src={imageUrl} alt={title} /> : null}
            </div>
            <div className="case__info">
                <p className="case__caption text text--small">{caption}</p>
                <h3 className="case__title h3">{title}</h3>
                <div className="case__text" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                    {subtitle}
                </div>
                <div className="case__text">{text}</div>
                <ul className="case__tags text text--small">
                    {tags.map((i) => (
                        <li key={i}>{i}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
