import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import './Footer.scss';
import { TextSplit } from 'components/text-split/TextSplit';
import { Link } from 'components/link/Link';
import AnimateIn from 'components/animate-in/AnimateIn';

function Arrow() {
    return (
        <div className="footer__arrow">
            <svg viewBox="0 0 88 48">
                <g transform="translate(0.000000, -0.362180)" fillRule="nonzero">
                    <path
                        d="M25.84375,0.33093 C25.399779,0.370746695 24.9818297,0.557724022 24.65625,0.86218 L0.65625,22.86218 C0.236361629,23.2414219 -0.00326700265,23.7807541 -0.00326700265,24.346555 C-0.00326700265,24.9123559 0.236361629,25.4516881 0.65625,25.83093 L24.65625,47.83093 C25.1860155,48.3146751 25.934101,48.4780291 26.6172936,48.2591487 C27.3004863,48.0402682 27.8143698,47.4726062 27.9643963,46.7710701 C28.1144228,46.0695341 27.8776629,45.3413437 27.34375,44.86218 L7.15625,26.36218 L86,26.36218 C86.7212718,26.3723806 87.3921627,25.9934294 87.7557754,25.3704343 C88.1193882,24.7474392 88.1193882,23.9769208 87.7557754,23.3539257 C87.3921627,22.7309306 86.7212718,22.3519794 86,22.36218 L7.15625,22.36218 L27.34375,3.83093 C27.9987609,3.26061509 28.210885,2.3327758 27.8687647,1.53449512 C27.5266444,0.736214446 26.7084683,0.249932419 25.84375,0.33093 Z"
                        transform="translate(44.012609, 24.338409) rotate(180.000000) translate(-44.012609, -24.338409) "
                    />
                </g>
            </svg>
        </div>
    );
}

export const Footer = ({
    direction = 'middle',
    leftColumn = 'services',
    rightColumn = 'contact',
}: {
    direction?: string;
    leftColumn?: any;
    rightColumn?: any;
}): JSX.Element => {
    const arrowState = {
        left: 'left',
        right: 'right',
    };
    const data = {
        home: {
            title: 'Want to explore our work.',
            cta: 'See cases',
            url: '/',
        },
        contact: {
            title: 'Let’s create something great.',
            cta: 'Contact us',
            url: '/contact',
        },
        services: {
            title: 'Curious about what we do.',
            cta: 'See our services',
            url: '/services',
        },
    };
    const [arrowDirection, setArrows] = useState(arrowState);
    const getKeyValue = <T extends object, U extends keyof T>(item: T) => (key: U) => item[key];

    useEffect(() => {
        if (direction === 'left' || direction === 'right') {
            setArrows({
                left: direction,
                right: direction,
            });
        }
    }, [direction]);

    return (
        <div className="footer">
            <div className="footer__item">
                <TextSplit type="h1b footer__title">{getKeyValue(getKeyValue(data)(leftColumn))('title')}</TextSplit>
                <AnimateIn>
                    <Link
                        className={cn('footer__link', arrowDirection.left)}
                        to={getKeyValue(getKeyValue(data)(leftColumn))('url')}
                    >
                        {getKeyValue(getKeyValue(data)(leftColumn))('cta')}
                        <Arrow />
                    </Link>
                </AnimateIn>
            </div>
            <div className="footer__item">
                <TextSplit type="h1b footer__title">{getKeyValue(getKeyValue(data)(rightColumn))('title')}</TextSplit>
                <AnimateIn>
                    <Link
                        className={cn('footer__link', arrowDirection.right)}
                        to={getKeyValue(getKeyValue(data)(rightColumn))('url')}
                    >
                        {getKeyValue(getKeyValue(data)(rightColumn))('cta')}
                        <Arrow />
                    </Link>
                </AnimateIn>
            </div>

            <div className="footer__item-privacy-policy">
                <Link className={cn('footer__link', arrowDirection.left)} to="/privacy-policy">
                    Privacy Policy
                </Link>
            </div>
        </div>
    );
};

// export default Footer;
