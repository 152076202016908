import React from 'react';
import Image from 'components/image/Image';

import './StudioLife.scss';
import { Column } from 'components/column/Column';
import { Container } from 'components/container/Container';

export default function StudioLife2(): JSX.Element {
    return (
        <div className="studio-life">
            <Container flex>
                <Column size="4">
                    <div>
                        <div className="studio-life__images__image">
                            <div className="e">
                                <Image src="/static/images/f.jpg" width={803} height={533} />
                            </div>
                        </div>
                    </div>
                </Column>
                <Column size="4">
                    <div className="studio-life__images__image">
                        <div className="d">
                            <Image src="/static/images/p3.jpg" width={800} height={800} />
                        </div>
                    </div>
                </Column>
            </Container>
        </div>
    );
}
