import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import './TextSplit.scss';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

const Splitting = require('splitting');

export const TextSplit = ({ children, type, isActive = true }: { children: any; type: string; isActive?: boolean }) => {
    const [visible, setVisible] = useState(false);
    const ref = useRef<any>();

    useEffect(() => {
        if (ref) {
            Splitting({
                by: 'lines',
            });
        }
    }, [ref]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.25 },
        );
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div ref={ref} data-splitting="true">
            <div className={cn(type, { 'is-active': visible && isActive })}>{children}</div>
        </div>
    );
};
