import React, { ReactNode } from 'react';

import './Column.scss';

export function Column({
    children,
    size,
    center = false,
}: {
    children: ReactNode;
    size: string;
    center?: boolean;
}): JSX.Element {
    return (
        <div
            className="max"
            style={{
                maxWidth: 1700,
                width: `${(parseFloat(size) / 12) * 100}%`,
                margin: center ? '0 auto' : '',
                position: 'relative',
            }}
        >
            {children}
        </div>
    );
}
