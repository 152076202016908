import React from 'react';
import Image from 'components/image/Image';

import './StudioLife.scss';

export default function StudioLife(): JSX.Element {
    return (
        <div className="studio-life">
            <h2 className="studio-life__title">
                <svg viewBox="0 0 88 48">
                    <g transform="translate(0.000000, -0.362180)" fillRule="nonzero">
                        <path
                            d="M25.84375,0.33093 C25.399779,0.370746695 24.9818297,0.557724022 24.65625,0.86218 L0.65625,22.86218 C0.236361629,23.2414219 -0.00326700265,23.7807541 -0.00326700265,24.346555 C-0.00326700265,24.9123559 0.236361629,25.4516881 0.65625,25.83093 L24.65625,47.83093 C25.1860155,48.3146751 25.934101,48.4780291 26.6172936,48.2591487 C27.3004863,48.0402682 27.8143698,47.4726062 27.9643963,46.7710701 C28.1144228,46.0695341 27.8776629,45.3413437 27.34375,44.86218 L7.15625,26.36218 L86,26.36218 C86.7212718,26.3723806 87.3921627,25.9934294 87.7557754,25.3704343 C88.1193882,24.7474392 88.1193882,23.9769208 87.7557754,23.3539257 C87.3921627,22.7309306 86.7212718,22.3519794 86,22.36218 L7.15625,22.36218 L27.34375,3.83093 C27.9987609,3.26061509 28.210885,2.3327758 27.8687647,1.53449512 C27.5266444,0.736214446 26.7084683,0.249932419 25.84375,0.33093 Z"
                            transform="translate(44.012609, 24.338409) rotate(180.000000) translate(-44.012609, -24.338409) "
                        />
                    </g>
                </svg>
                Studio Life
            </h2>

            <div className="studio-life__images">
                <div className="studio-life__images__image">
                    <div className="a">
                        <Image src="/static/images/mr1.jpg" width={600} height={804} />
                    </div>
                </div>
                <div className="studio-life__images__image">
                    <div className="b">
                        <Image src="/static/images/Ida.jpg" width={700} height={700} />
                    </div>
                </div>
                <div className="studio-life__images__image">
                    <div className="c">
                        <Image src="/static/images/p2@1.jpg" width={900} height={600} />
                    </div>
                </div>
            </div>
        </div>
    );
}
