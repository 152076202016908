import React from 'react';
import Image from 'components/image/Image';

import './StudioLife.scss';

export default function StudioLife3(): JSX.Element {
    return (
        <div className="studio-life">
            <div className="g">
                <Image src="/static/images/DAL1030144.jpg" width={700} height={467} />
            </div>
        </div>
    );
}
