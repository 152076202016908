import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Footer } from 'components/footer/Footer';
import Page from 'components/page/Page';
import { Hero, HeroTextOverlay } from 'components/hero/Hero'; 
import { Cases, Case } from 'components/cases/Cases';
import { Container } from 'components/container/Container';
import { TextBlock, TextBlockTitle, TextBlockTotalCount } from 'components/text-block/TextBlock';
import { TextBlock1 } from 'components/text-block/TextBlock1';

import { LogoModule } from 'components/logo-module/LogoModule';

const Home: React.FC<RouteComponentProps & { directionLeft: boolean }> = ({
    directionLeft,
}: {
    directionLeft: boolean;
}) => {
    const [sticky, setSticky] = useState(true);
    const [introDone] = useState(true);
    const watcherId = 'sticky-text'; 

    const awards = { webby: 5, dda: 7, cc: 10, indigo: 6, lovie: 3, aw: 11, css: 9, rambuk: 2 };
    const values = Object.values(awards);

    const sum = values.reduce(function sum(a, b) {
        return a + b;
    }, 0);

    return (
        <> 
            <HeroTextOverlay
                watcherId={watcherId}
                sticky={sticky}
                toggleSticky={setSticky}
                introDone={introDone}
                directionLeft={directionLeft}
            />

            <Page title="NoA Ignite" directionLeft={directionLeft}>
                <h1 className="visually-hidden">NoA Ignite</h1>

                <Hero watcherId={watcherId} sticky={sticky} introDone={introDone} />
                <Cases title="Selected works">
                    <Case
                        caption="1.0 Case"
                        title="Embracing the unconventional to find the perfect fit"
                        subtitle="Han Kjøbenhavn"
                        slug="han-kobenhavn"
                        gold
                        text="Together with the Danish fashion brand Han Kjøbenhavn, we created an uncompromising and unconventional universe, built upon creativity, storytelling and craftsmanship – with the ambition of sewing together brand and eCommerce. "
                        tags={['Strategy', 'Design', 'E-Commerce']}
                        posterUrl="/static/images/han.jpg"
                        videoUrl="/static/videos/hgwsite_case_HAN-2.mp4"
                    />

                    <Case
                        caption="2.0 Case"
                        title="A sea change in shipping "
                        subtitle="Zero North"
                        slug="zero-north"
                        green
                        text="ZeroNorth was founded to change the shipping industry through digitalisation. We worked with them to create a digital platform and presence from where they could embark on their ambitious journey.  "
                        tags={['Design', 'Platform', 'Development']}
                        videoUrl="/static/videos/hgwsite_case_ZN-3.mp4"
                        posterUrl="/static/images/ZeroNorth.jpg"
                    />
                    <Case
                        caption="3.0 Case"
                        title="Accelerating the future of digital shopping"
                        subtitle="Coop Denmark"
                        slug="coop-denmark"
                        lightGreen
                        text="With Scan &amp; Betal for COOP, we have created a shopping experience that is more efficient and smarter. Not for the sake of doing something digital, but merely using technology to make life easier for humans."
                        tags={['Product & Services', 'Customer experience', 'Commerce']}
                        posterUrl="/static/images/Scan-betal.jpg"
                        videoUrl="/static/videos/hgwsite_case_BB-3.mp4"
                    />
                </Cases>

                <TextBlock divider>
                    <TextBlockTitle>Our Clients</TextBlockTitle>
                    <p>Across industies, markets and size we collaborate with our clients to push borders.</p>
                </TextBlock>
                {/* <Clients>
                    <Client logo="/static/images/hello-great-works-tv-2.svg" name="TV2" />
                    <Client logo="/static/images/hello-great-works-maersk.svg" name="Maersk" />
                    <Client logo="/static/images/hello-great-works-google.svg" name="Google" />
                    <Client logo="/static/images/hello-great-works-orsted.svg" name="Ørsted" />
                    <Client logo="/static/images/hello-great-works-coop.svg" name="Coop" />
                    <Client logo="/static/images/hello-great-works-novo-nordisk.svg" name="Novo Nordisk" />
                    <Client logo="/static/images/hello-great-works-danske-spil.svg" name="Danske Spil" />
                    <Client logo="/static/images/hello-great-works-tdc.svg" name="TDC" />
                    <Client logo="/static/images/hello-great-works-roskilde-festival.svg" name="Roskilde Festival" />
                    <Client logo="/static/images/hello-great-works-nordea.svg" name="Nordea" />
                    <Client logo="/static/images/hello-great-works-mobile-pay.svg" name="MobilePay" />
                    <Client logo="/static/images/hello-great-works-zero-north.svg" name="ZeroNorth" />
                    <Client logo="/static/images/hello-great-works-dsb.svg" name="DSB" />
                    <Client logo="/static/images/hello-great-works-velux.svg" name="Velux" />
                    <Client logo="/static/images/hello-great-works-interflora.svg" name="Interflora" />
                    <Client logo="/static/images/hello-great-works-danske-bank.svg" name="DanskeBank" />
                    <Client logo="/static/images/hello-great-works-nuuday.svg" name="Nuuday" />
                    <Client logo="/static/images/hello-great-works-codan.svg" name="Codan" />
                    <Client logo="/static/images/Coloplast.svg" name="Coloplast" />
                    <Client logo="/static/images/hello-great-works-han-kjobenhavn.svg" name="Han København" />
                    <Client logo="/static/images/hello-great-works-you-see.svg" name="YouSee" />
                </Clients> */}

                <LogoModule />
                <TextBlock right>
                    <TextBlockTitle>Recognition &amp; Awards</TextBlockTitle>

                    <p>No matter how good you think you are, it’s about how good you are in the eyes of others.</p>
                </TextBlock>
                <Container>
                    <div style={{ position: 'relative' }}>
                        <TextBlockTitle count={awards.webby}>WEBBY</TextBlockTitle>
                        <TextBlockTitle count={awards.dda}>DANISH DIGITAL AWARDS</TextBlockTitle>
                        <TextBlockTitle count={awards.cc}>CREATIVE CIRCLE</TextBlockTitle>
                        <TextBlockTitle count={awards.indigo}>INDIGO</TextBlockTitle>
                        <TextBlockTitle count={awards.lovie}>LOVIE</TextBlockTitle>
                        <TextBlockTitle count={awards.aw}>AWWWARDS</TextBlockTitle>
                        <TextBlockTitle count={awards.rambuk}>RAMBUKKEN</TextBlockTitle>
                        <TextBlockTitle count={awards.css}>CSS DESIGN AWARDS</TextBlockTitle>
                        <TextBlock1 right>
                            <TextBlockTotalCount>{sum}</TextBlockTotalCount>
                        </TextBlock1>
                    </div>
                </Container>
                <div style={{ marginBottom: '8em' }} />

                <Footer />
            </Page>
        </>
    );
};

export default Home;
